export class User {
    firstName: string;
    lastName: string;
    email: string;

    constructor(firstNameVal: string = '', lastNameVal: string = '', emailVal: string = '') {
        this.firstName = firstNameVal;
        this.lastName = lastNameVal;
        this.email = emailVal;
    }
}
