import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { User } from '../models/user.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
    constructor(private httpClient: HttpClient, private router: Router) { }
    login(values) {

        const headers = new HttpHeaders({ 'Authorization': 'Basic ' + btoa((values.username).toLowerCase() + ':' + values.password)});
        return this.httpClient.post(environment.endpoint_auth + '/token/endpoint', values, { headers: headers })
            .pipe(
                map(
                    (response: any) => {
                        // var expires = new Date();
                        // expires.setTime(expires.getTime() + 12 * 3600000);
                        // this.setToken(response.access_token, expires);
                        this.setCookie('user_access_token', response.access_token, 12);
                        // this.router.navigate(['/inventory']);
                        return 'Login success';
                    }
                ),
                catchError(val => throwError(val))
            );
    }

    logout() {
        // localStorage.removeItem('user_access_token');
        this.setCookie('user_access_token', '', -1);
        localStorage.clear();
        this.router.navigate(['/portal/login']);
    }

    // setToken(tokenId: string, expired: Date) {
    //     let tokenObject = { 'token': tokenId, 'expired': expired };
    //     localStorage.setItem('user_access_token', JSON.stringify(tokenObject));
    // }

    getToken() {
        // let tokenValue: any = JSON.parse(localStorage.getItem('user_access_token'));
        // return (tokenValue && tokenValue.token)?tokenValue.token:null;
        return this.getCookie('user_access_token');
    }

    decodeToken(): User {
        const token = this.getToken();
        if (!token) {
            console.log('Token is not available');
            return new User;
        }

        const parts = token.split('.');
        if (parts.length !== 3) {
            console.log('JWT must have 3 parts');
            return new User();
        }

        let output = parts[1].replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0: { break; }
            case 2: { output += '=='; break; }
            case 3: { output += '='; break; }
            default: {
                throw new Error('Illegal base64url string!');
            }
        }
        const decoded = decodeURIComponent(escape(atob(output))); // polyfill https://github.com/davidchambers/Base64.js

        if (!decoded) {
            console.log('Cannot decode the token');
            return new User();
        }

        const parseToken: any = JSON.parse(decoded);
        return new User(parseToken.user_details.firstName, parseToken.user_details.lastName, parseToken.user_name);
    }
    getDecodedToken() {
        const token = this.getToken();
        if (!token) {
            return new User;
        }

        const parts = token.split('.');
        if (parts.length !== 3) {
            return new User();
        }

        let output = parts[1].replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0: { break; }
            case 2: { output += '=='; break; }
            case 3: { output += '='; break; }
            default: {
                throw new Error('Illegal base64url string!');
            }
        }
        const decoded = decodeURIComponent(escape(atob(output))); // polyfill https://github.com/davidchambers/Base64.js

        if (!decoded) {
            console.log('Cannot decode the token');
            return new User();
        }

        const parseToken: any = JSON.parse(decoded);
        return parseToken;
    }

    isAuthenticated() {
        return this.getToken() !== null;
    }

    setCookie(cname, cvalue, hours) {
        const d = new Date();
        d.setTime(d.getTime() + hours * 3600000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    getCookie(cname) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }
}
